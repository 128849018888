import React from 'react';
import PcTop from "./pc"
import H5Top from "./h5"

const ComponentName = (props?:{}) => {
  return (
    <div>
      <PcTop />
      <H5Top />
    </div>
  );
};

export default ComponentName;