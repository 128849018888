import React from 'react';
import protocol from './index.module.css';

const ComponentName = (props?: {}) => {
    return (
        <div className={protocol.h5}>
            <div className={protocol.h5_bg}></div>
        </div>
    );
};

export default ComponentName;
