import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Login } from '../API';
import { ed25519 } from '@noble/curves/ed25519';
import bs58 from 'bs58';
import { useDispatch, useSelector } from 'react-redux';
import { createLoginSuccessAction } from '../store/actions';
import { useWallet } from '@solana/wallet-adapter-react';
import { getQueryParam } from '../utils/getUrlParamsLegacy';
import Hander from '../components/hander';
import FooterPc from '../components/footer/pc';
import FooterH5 from '../components/footer/h5';
import { showLoding } from '../utils/tool';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

const MainLayout: React.FC = () => {
    let { t, i18n } = useTranslation();
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const token = useSelector((state: any) => state?.token);

    const { publicKey, signMessage, signTransaction, connected, autoConnect, disconnect } = useWallet();
    const { setVisible } = useWalletModal();
    const handleLoad = (sign: any, signMsg: any) => {
        const account: any = publicKey?.toBase58();
        if (!account) return;
        const exampleValue = getQueryParam('refereeUserAddress') || ''; // 推荐地址
        Login({
            refereeUserAddress: exampleValue,
            userAddress: account,
            sign,
            signMsg,
        })
            .then((res: any) => {
                if (res.code === 200) {
                    dispatch(createLoginSuccessAction(res.data.token, account as string));
                }
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                showLoding(false);
            });
    };

    const sendMessage = async () => {
        try {
            showLoding(true);
            if (!publicKey) throw new Error('Wallet not connected!');
            if (!signMessage) throw new Error('Wallet does not support message signing!');

            const _msg = `${
                window.location.host
            } wants you to sign in with your Solana account:\n${publicKey.toBase58()}\n\nPlease sign in.`;
            const message = new TextEncoder().encode(_msg);
            const signature = await signMessage(message);

            handleLoad(bs58.encode(signature), _msg);
            // if (!ed25519.verify(signature, message, publicKey.toBytes())) throw new Error('Message signature invalid!');
            console.log(`Message signature: ${bs58.encode(signature)}`);
        } catch (error: any) {
            showLoding(false);
            disconnect();
            console.log(`Sign Message failed: ${error?.message}`);
        }
    };

    useEffect(() => {
        setVisible(!publicKey);
    }, [publicKey]);

    const [isSendMessage, setIsSendMessage] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (publicKey && connected && isSendMessage) {
            // if (wallet?.adapter.name !== 'Phantom') {
            //     alert('please_switch_phantom');
            //     disconnect().catch(() => {
            //         setVisible(true);
            //     });
            //     return;
            // }
            setIsSendMessage(false);
            sendMessage();
            setTimeout(() => {
                setIsSendMessage(true);
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, connected]);

    // 监听路径变化
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(location.pathname);
    }, [location.pathname]);

    const footerComponent = () => {
        switch (location.pathname) {
            case '/mint':
            case '/staking':
            case '/market':
            case '/depin':
                return null; // 或者直接返回空字符串 ''
            default:
                return (
                    <div>
                        <FooterPc />
                        <FooterH5 />
                    </div>
                );
        }
    };

    return (
        <div className="UUContainer">
            <Hander />
            <div className="MainContent">
                {/* 公共组件 */}
                <Outlet />
            </div>
            {footerComponent() && footerComponent()}
        </div>
    );
};
export default MainLayout;
